import React, { useEffect, useState } from 'react';
import { useContractFunction, useEthers } from '@usedapp/core'; // Import useEthers
import { Contract, utils } from 'ethers';
import { toast } from 'react-toastify';
import CoinflipContractABI from '../../../abi/coinflip.json';
import axios from 'axios';
import { useConnectWallet } from '@web3-onboard/react';
import {Link} from "react-router-dom";
import { ethers } from "ethers";
import rules from '../../common/coinflip/coinflip_rules.json'
import Popup from '.././coinflipPopup';
import CoinflipCard from '../../common/coinflip/CoinflipCard';
import coinflipConfig from "./config.json";


const CoinflipGame = () => {

    const {activateBrowserWallet, account, activate, deactivate, library} = useEthers();
    const ContractInterface = new utils.Interface(CoinflipContractABI);

    // State variables
    const [selectedSide, setSelectedSide] = useState(null); // 'Heads' or 'Tails'
    const [betAmount, setBetAmount] = useState(0);
    const [isBetting, setIsBetting] = useState(false);
    const [isFlipping, setIsFlipping] = useState(false);
    const [flipOutcome, setFlipOutcome] = useState(null);
    const [rewardBalance, setRewardBalance] = useState(0);
    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
    const [signer, setSigner] = useState(null);
    const [croBalance, setCroBalance] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLatest10ModalOpen, setisLatest10ModalOpen] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [popupOutcome, setPopupOutcome] = useState(null);
    const [player, setPlayer] = useState(''); // Initialize player state
    const [result, setResult] = useState(''); // Initialize result state
    const [coinflips, setCoinflips] = useState([]);


    const coinflipConfig = require('./config.json');

    const [currentPage, setCurrentPage] = useState(1);
    const flipsPerPage = 2;

    useEffect(() => {
        let isMounted = true; // Flag to track if component is mounted

        // Check if library and account are defined

        if (library && account) {
            // Check if window.ethereum is available
            if (window.ethereum) {
                const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = web3Provider.getSigner();
                if (isMounted) {
                    setSigner(signer);
                }
            } else {
                console.error('Ethereum provider not available');
            }
        } else {
            console.log('Library or account not available');
        }

        return () => {
            isMounted = false; // Set flag to false when component unmounts
        };
    }, [library, account]);

    const CoinflipContract = signer ? new Contract("0x11b996419Ed9A2CceE1bfdFB89B137927FE1416c", ContractInterface, signer) : null;


    // const sendDiscordWin = async () => {
    //     try {
    //         console.log(`0x: ${wallet?.accounts[0].address}\n$$: ${betAmount}\nheads or tails: ${selectedSide}`);
    //         const response = await axios.post('http://188.93.139.120:3010/utils/coinflipwin', {
    //             "0x": `${wallet?.accounts[0].address}`,
    //             "bet": `${betAmount}`,
    //             "headsOrTails": `${selectedSide}`,
    //         }, {
    //             headers: {
    //                 auth: "timtim_XiAMZH352IJScWUr3E5DdVgC7nc3MmJipT2OjyzVw0OkionlMBrUtTYszUZ46CUeFP0q1pJh3O3dyF6esieDALfWWOszwz56g4G9ACGcEznHQ3hcCAnlhinqL"
    //             }
    //         });
    //         console.log('Post request sent successfully:', response.data);
    //     } catch (error) {
    //         console.error('Error sending post request:', error);
    //     }
    // };

    const checkPlayerFlipStatus = async (player) => {
        try {
            const response = await axios.get(`${coinflipConfig.apiURI}/player/flip-status?player=${player}`);

            const { hasFlipped20Times } = response.data;

            if (player.toLowerCase()!=="0x1c968555856C27382e2432E045D9332929dBf6C8".toLowerCase()) {
                if (hasFlipped20Times) {
                    // Show toast error and prevent further action
                    toast.error("You have already flipped 20 times in the last 24 hours. Please try again later.");
                    return false; // Prevent further actions
                }
            }

            return true; // Proceed with further actions
        } catch (error) {
            console.error("Error checking flip status:", error);
            toast.error("An error occurred while checking your flip status. Please try again.");
            return false; // Prevent further actions
        }
    };

    const handleCoinFlip = async () => {
        // Check player's flip status before proceeding
        const canProceed = await checkPlayerFlipStatus(wallet?.accounts[0].address);

        if (!canProceed) {
            return; // Exit if the player has more than 10 flips
        }

        // Proceed with the coin flip logic here
        console.log("Proceeding with coin flip...");

        await placeBet()
    };

    async function addCoinFlip(player, result, selected, betAmount) {
        try {
            // Check if player and result are set
            if (!player || !result || !selected) {
                throw new Error('Please provide player, result and selected side.');
            }

            // Make a POST request to add a new coinflip
            const response = await axios.post(
                `${coinflipConfig.apiURI}/coinflips`, // Your backend endpoint
                {player, result, selected, betAmount, chain: 'Cronos'}, // Data to send in the request body
                {headers: {'Content-Type': 'application/json'}} // Specify JSON content type
            );

            // Log the response data
            console.log('Coinflip added:', response.data);
        } catch (error) {
            console.error('Error adding coinflip:', error);
        }
    }

    // Function to fetch reward balance from the contract
    const fetchRewardBalance = async () => {
        try {
            const balance = await CoinflipContract.getWinningsBalance(); // Call getWinningsBalance instead of getRewardBalance
            setRewardBalance(balance.toString()); // Convert BigNumber to string before setting state
        } catch (error) {
            console.error('Error fetching reward balance:', error);
        }
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    useEffect(() => {
        const fetchFlips = async () => {
            try {
                const response = await axios.get(`${coinflipConfig.apiURI}/coinflips/Cronos`);
                setCoinflips(response.data);
            } catch (error) {
                console.error('Error fetching flips:', error);
            }
        };

        fetchFlips();
    }, []);

    const indexOfLastFlip = currentPage * flipsPerPage;
    const indexOfFirstFlip = indexOfLastFlip - flipsPerPage;
    const currentFlips = coinflips.slice(indexOfFirstFlip, indexOfLastFlip);

    const handleClickNext = () => {
        if (currentPage < Math.ceil(coinflips.length / flipsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleClickPrev = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };


    useEffect(() => {
        if (account) {
            fetchRewardBalance(); // Fetch reward balance when account is connected
        }
    }, [account]);

    useEffect(() => {
        if (account) {
            const croBalance = ethers.utils.formatEther(rewardBalance); // Convert wei to CRO
            setCroBalance(croBalance)
        }
    }, [rewardBalance, account]);


    // Function to handle placing a bet
    const placeBet = async () => {
        try {
            if (!account) {
                activateBrowserWallet();
                return;
            }
            if (account) {
                if (!selectedSide) {
                    throw new Error('Please select Heads or Tails.');
                }
                if (betAmount <= 0) {
                    throw new Error('Please enter a valid bet amount.');
                }
                let sideInt = null;
                if (selectedSide === 'Heads') {
                    sideInt = 0;
                } else {
                    sideInt = 1
                }

                setIsBetting(true);
                setIsFlipping(true);
                // Call the smart contract method to place bet with gasLimit
                const value = ethers.utils.parseEther(`${betAmount}`);
                const gasLimit = await CoinflipContract.estimateGas.flip(sideInt, {value});

                const extraGas = Math.ceil(gasLimit.toNumber() * 1.7);


                // Calculate the total gas limit
                const totalGasLimit = gasLimit.add(extraGas);

                // Send the transaction with the adjusted gas limit and the value
                const transaction = await CoinflipContract.flip(sideInt, {
                    value: value,
                    gasLimit: totalGasLimit
                });
                const receipt = await transaction.wait();

                const eventDataInt = parseInt(receipt.events[0].data.toString(), 16);

                const flipOutcome = eventDataInt === sideInt ? 'win' : 'lose';
                let headsOrTailsOutcome;

                if (eventDataInt === 0) {
                    headsOrTailsOutcome = 'Heads';
                } else {
                    headsOrTailsOutcome = 'Tails';
                }

                setFlipOutcome(flipOutcome);

                console.log(parseInt(receipt.events[0].data.toString(), 16))

                console.log(`FlipOutcome ${flipOutcome}`)
                console.log(`SelectedSide ${selectedSide}`);

                await addCoinFlip(wallet?.accounts[0].address, headsOrTailsOutcome, selectedSide, betAmount);

                if (parseInt(receipt.events[0].data.toString(), 16) === sideInt) {
                    setPopupOutcome('win');
                    setShowPopup(true);
                    setTimeout(() => setShowPopup(false), 10000); // Hide popup after 10 seconds
                    // new Audio(winSound).play(); // Play winning sound
                    fetchRewardBalance(); // Fetch updated reward balance after winning
                } else {
                    setPopupOutcome('lose');
                    setShowPopup(true);
                    setTimeout(() => setShowPopup(false), 10000); // Hide popup after 10 seconds
                    // new Audio(loseSound).play(); // Play losing sound
                    fetchRewardBalance()
                }
                // If the bet was won, send a POST request
                if (parseInt(receipt.events[0].data.toString(), 16) === sideInt) {
                    fetchRewardBalance(); // Fetch updated reward balance after winning
                    console.log("ok u win")
                }

            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setIsBetting(false);
            setIsFlipping(false);
        }
    };

    // Function to handle claiming rewards
    const claimRewards = async () => {
        try {
            if (!account) {
                activateBrowserWallet();
                return;
            }
            if (wallet) {
                // Call the smart contract method to claim rewards
                const transaction = await CoinflipContract.withdrawUserWinnings();
                await transaction.wait();
                toast.success('Rewards claimed successfully!');
                await fetchRewardBalance(); // Fetch updated reward balance after claiming rewards
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    const centeredBoxStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        position: 'relative', // Add position relative
    };

    const borderedBoxStyle = {
        padding: '20px',
        textAlign: 'center',
        position: 'relative', // Add position relative
    };

    const gifContainerStyle = {
        position: 'absolute',
        top: '-50px', // Adjust as needed
        left: '50%',
        transform: 'translateX(-50%)', // Move to center horizontally
        zIndex: 1, // Set higher z-index to ensure it's above the bordered box
    };

    const buttonStyle = {
        borderRadius: '20px',
        backgroundColor: '#ffffff',
        color: '#0047FF', // Blue color
        padding: '10px 20px',
        margin: '5px',
        cursor: 'pointer',
        border: 'none',
        outline: 'none',
    };

    const selectedButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#0047FF',
        color: '#ffffff',
    };

    const handleBetAmount = (amount) => {
        setBetAmount(amount);
    };

    const headsButtonStyle = {
        ...buttonStyle,
        backgroundColor: selectedSide === 'Heads' ? '#0047FF' : '#ffffff',
        color: selectedSide === 'Heads' ? '#ffffff' : '#0047FF',
    };

    const tailsButtonStyle = {
        ...buttonStyle,
        backgroundColor: selectedSide === 'Tails' ? '#0047FF' : '#ffffff',
        color: selectedSide === 'Tails' ? '#ffffff' : '#0047FF',
    };

    const modalStyle = {
        position: 'fixed',
        zIndex: '1000',
        left: '0',
        top: '0',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgba(0,0,0,0.4)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    const modalContentStyle = {
        // backgroundColor: 'rgba(254,254,254,0)',
        padding: '20px',
        width: '80%',
        maxWidth: '600px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        position: 'relative'
    };

    const closeButtonStyle = {
        color: '#aaa',
        position: 'absolute',
        top: '10px',
        right: '10px',
        fontSize: '28px',
        fontWeight: 'bold',
        cursor: 'pointer'
    };

    const linkButtonStyle = {
        background: 'none',
        border: 'none',
        color: 'blue',
        textDecoration: 'underline',
        cursor: 'pointer',
        padding: '0',
        fontSize: 'inherit'
    };



    return (
        <div style={centeredBoxStyle}>
            <div className="card project-card" style={borderedBoxStyle}>
                <h2>Timmy Flip (CRONOS)</h2>
                <div className="betting-options">
                    <button style={headsButtonStyle} onClick={() => setSelectedSide('Heads')}
                            disabled={isBetting}>Heads
                    </button>
                    <button style={tailsButtonStyle} onClick={() => setSelectedSide('Tails')}
                            disabled={isBetting}>Tails
                    </button>
                </div>
                <div>
                    <button
                        style={betAmount === 10 ? selectedButtonStyle : buttonStyle}
                        onClick={() => handleBetAmount(10)}
                        disabled={isBetting}
                    >
                        10 CRO
                    </button>
                    <button
                        style={betAmount === 20 ? selectedButtonStyle : buttonStyle}
                        onClick={() => handleBetAmount(20)}
                        disabled={isBetting}
                    >
                        20 CRO
                    </button>
                    <button
                        style={betAmount === 50 ? selectedButtonStyle : buttonStyle}
                        onClick={() => handleBetAmount(50)}
                        disabled={isBetting}
                    >
                        50 CRO
                    </button>
                </div>
                <button style={buttonStyle} onClick={handleCoinFlip} disabled={!selectedSide || isBetting}>
                    {isBetting ? 'Placing Bet...' : 'Place Bet'}
                </button>
                <div>
                    <button
                        style={{...buttonStyle, marginTop: '20px'}}
                        onClick={claimRewards}
                        disabled={croBalance === 0 || isBetting}
                    >
                        Claim {croBalance} CRO
                    </button>
                    {/*{flipOutcome && (*/}
                    {/*    <div>*/}
                    {/*        <h3>{flipOutcome === 'win' ? 'Congratulations! You won!' : 'Sorry! You lost!'}</h3>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </div>
                <br/>
                <button style={{...buttonStyle, marginTop: '20px'}} onClick={() => setisLatest10ModalOpen(true)}>
                    View flips.
                </button>
                <br/>
                <button style={linkButtonStyle} onClick={() => setIsModalOpen(true)}>
                    View rules and information.
                </button>

            </div>

            {isModalOpen && (
                <div className="modal" style={modalStyle}>
                    <div className="modal-content" style={modalContentStyle}>
            <span className="close" style={closeButtonStyle} onClick={() => setIsModalOpen(false)}>
              &times;
            </span>
                        <div className="single-item-content no-hover item ml-lg-4">
                            <h3 className="m-0">Timmy Flip Rules and info.</h3>
                            <br /> {/* Add a line break */}
                            <ul style={{ listStyleType: 'decimal' }}>
                                {rules.map((rule, index) => (
                                    <li key={index}>{rule}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}

            {isLatest10ModalOpen && (
                <div className="modal" style={modalStyle}>
                    <div className="modal-content" style={modalContentStyle}>
            <span className="close" style={closeButtonStyle} onClick={() => setisLatest10ModalOpen(false)}>
              &times;
            </span>
                        <div className="single-item-content no-hover item ml-lg-4">
                            <h3 className="m-0">Timmy flips.</h3>
                            <br/> {/* Add a line break */}
                            {currentFlips.map((flip) => (
                                <CoinflipCard key={flip._id} coinflip={flip}/>
                            ))}
                            <div className="pagination">
                                <button onClick={handleClickPrev} disabled={currentPage === 1}>
                                    Previous
                                </button>
                                <button onClick={handleClickNext}
                                        disabled={currentPage === Math.ceil(coinflips.length / flipsPerPage)}>
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showPopup && <Popup outcome={popupOutcome} onClose={handleClosePopup}/>}
        </div>
    );
};

export default CoinflipGame;